import _ from 'lodash'
import { fieldsStore } from '../../editor-app/core/preset/fields/fields-store'
import { FIELD_COMPONENT_TYPES } from '@wix/forms-common'
import { FIELDS } from '../../constants/roles'
import { PAYMENT_OPTIONS } from '../payment-wizard-panel/constants'

const escapeRegExpSpecialCharcters = (str) => str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&')

const getDuplicateSuffix = (value: string, existingValues: string[], suffixTemplate: string) => {
  const templateParts = suffixTemplate ? suffixTemplate.split('{0}') : []
  const templateStart = templateParts[0] || '_'
  const templateEnd = templateParts[1] || ''
  const duplicateValueRegExp = new RegExp(
    `${escapeRegExpSpecialCharcters(value + templateStart)}(\\d+)${escapeRegExpSpecialCharcters(
      templateEnd,
    )}`,
  )

  const duplicateNumber =
    _(existingValues)
      .map((currValue) => {
        const valueToTest =
          value === currValue ? `${currValue + templateStart}0${templateEnd}` : currValue
        const valueMatch = valueToTest.match(duplicateValueRegExp)

        return valueMatch ? parseInt(valueMatch[1], 10) : null
      })
      .filter((currValue) => currValue !== null)
      .max() + 1

  return templateStart + duplicateNumber + templateEnd
}

const getUniqueValue = (value: string, existingValues: string[], suffixTemplate: string) => {
  if (!_.includes(existingValues, value)) {
    return value
  }

  return value + getDuplicateSuffix(value, existingValues, suffixTemplate)
}

export const filterNonLabelFields = (fields: FormField[]) => {
  return _.filter(fields, (item) => !fieldsStore.get(item.fieldType).alwaysShowLabel)
}

export const shouldShowFieldLabel = (fieldType: FieldPreset) =>
  !!_.get(fieldsStore.get(fieldType), 'alwaysShowLabel')
export const isDateField = (fieldType: FieldPreset) =>
  _.get(fieldsStore.get(fieldType), 'properties.componentType') ===
  FIELD_COMPONENT_TYPES.DATE_PICKER
export const isSingleCheckbox = (fieldType: FieldPreset) =>
  _.get(fieldsStore.get(fieldType), 'properties.componentType') ===
  FIELD_COMPONENT_TYPES.SINGLE_CHECKBOX
export const isFileUploader = (fieldType: FieldPreset) =>
  _.get(fieldsStore.get(fieldType), 'properties.componentType') ===
  FIELD_COMPONENT_TYPES.FILE_UPLOADER
export const isFieldWithOptions = (fieldType: FieldPreset) =>
  !!_.get(fieldsStore.get(fieldType), 'fieldWithOptions')

export const isFieldHasComplexPlaceholder = (field: FormField) =>
  typeof _.get(field, 'placeholder.text') === 'string'
export const isCheckboxOption = (fielOption) => fielOption.checked !== undefined
export const createOptionValue = (options: FieldOption[], label: string) => {
  const SUFFIX_TEMPLATE = ' {0}'
  const existingValues = _.map(options, 'value')
  existingValues.push(label)

  return getUniqueValue(label, existingValues, SUFFIX_TEMPLATE)
}

export const getFieldName = (fieldProperties) => {
  const { label, placeholder, buttonLabel, showLabel, crmLabel, fieldType } = fieldProperties
  const realPlaceholder = isFileUploader(fieldType)
    ? buttonLabel
    : _.isString(placeholder)
    ? placeholder
    : _.get(placeholder, 'text')
  const labelTrimmed = _.trim(label)
  const placeholderTrimmed = _.trim(realPlaceholder)
  return (showLabel && labelTrimmed) || placeholderTrimmed || crmLabel
}

export const isPaymentField = (field: FormField): boolean =>
  field.role === FIELDS.ROLE_FIELD_ITEMS_LIST || field.role === FIELDS.ROLE_FIELD_CUSTOM_AMOUNT
export const isOptionDefinedWithFields = (selectedPaymentOption: PAYMENT_OPTIONS): boolean =>
  selectedPaymentOption === PAYMENT_OPTIONS.CUSTOM || selectedPaymentOption === PAYMENT_OPTIONS.LIST
