import { FormsFieldPreset } from '@wix/forms-common'
import { COMPLEX_PHONE_ROLES, FIELDS } from '../../../constants/roles'
import { getFormWidth, orderFieldsByRowsAndCols } from '../layout/utils'
import { getFieldLayout, getPreviousComplexComponent } from '../services/form-service'
import { getComponentTypeLayoutProp } from '../services/layout-service'
import _ from 'lodash'
import { CountryCodePlaceholderChoice } from './api'
import {
  CountriesCodesKeys,
  COUNTRIES_CODES,
} from '../preset/fields/complex-fields/complex-phone/constants'
import { getCountryCodeByGEO } from '../preset/fields/complex-fields/complex-phone/utils'

export type FieldLayoutProp = {
  [key: string]: PROPERTIES
} | null

export type FieldPropHandler = (fieldType: FieldPreset, value: PROPERTIES) => FieldLayoutProp | null
export type FieldDataHandler = FieldPropHandler

export const isInnerComplexPhoneField = (role: string) =>
  role === COMPLEX_PHONE_ROLES.DROPDOWN || role === COMPLEX_PHONE_ROLES.TEXT

export const getTextAlignmentProp: FieldPropHandler = (fieldType, value) => {
  return getComponentTypeLayoutProp(fieldType, 'textAlignment', value)
}

export const getLabelMarginProp: FieldPropHandler = (fieldType, value) => {
  return getComponentTypeLayoutProp(fieldType, 'labelMargin', value)
}

export const getLabelPaddingProp: FieldPropHandler = (fieldType, value) => {
  return getComponentTypeLayoutProp(fieldType, 'labelPadding', value)
}

export const getInputTextPaddingProp: FieldPropHandler = (fieldType, value) => {
  return getComponentTypeLayoutProp(fieldType, 'textPadding', value)
}

export const getPhoneNumberField = (subFields: FormField[]): FormField => {
  return subFields.find((f: FormField) => f.role === COMPLEX_PHONE_ROLES.TEXT)
}

export const getCountryCodeField = (subFields: FormField[]): FormField => {
  return subFields.find((f: FormField) => f.role === COMPLEX_PHONE_ROLES.DROPDOWN)
}

export const getCountryCodeOptions = (subFields: FormField[]): FieldOption[] => {
  const countryCodeField = getCountryCodeField(subFields)
  return countryCodeField ? countryCodeField.options : []
}

const orderPhoneStructuresByRowsAndCols = ({
  widgetFieldLayout,
}: {
  widgetFieldLayout: FieldLayout
}) => {
  const phoneFormField = {
    fieldType: FormsFieldPreset.COMPLEX_PHONE_TEXT,
  } as FormField
  const phoneFormDropdown = {
    fieldType: FormsFieldPreset.COMPLEX_PHONE_DROPDOWN,
  } as FormField
  const widgetFormField = {
    fieldType: FormsFieldPreset.COMPLEX_PHONE_WIDGET,
    childFields: [phoneFormDropdown, phoneFormField],
  }
  return orderFieldsByRowsAndCols(widgetFormField, widgetFieldLayout)
}

export const calcNewPhoneLayout = (
  fieldsData: FormField[],
  fieldComponent: Partial<RawComponentStructure>,
  dropdownField: Partial<RawComponentStructure>,
  phoneTextField: Partial<RawComponentStructure>,
  formWidth: number,
) => {
  const previousComplexField = getPreviousComplexComponent(
    FIELDS.ROLE_FIELD_COMPLEX_PHONE_WIDGET,
    fieldsData,
    2,
  )

  const complexPhoneLayout = previousComplexField
    ? {
        ...fieldComponent.layout,
        height: previousComplexField.height,
        width: previousComplexField.width,
      }
    : {
        ...fieldComponent.layout,
        width: getFormWidth(fieldsData, formWidth),
        height: Math.max(dropdownField.layout.height, phoneTextField.layout.height),
      }
  let childFieldsWithCorrectLayout = []
  if (previousComplexField) {
    childFieldsWithCorrectLayout = [
      {
        ...dropdownField,
        layout: getFieldLayout(getCountryCodeField(previousComplexField.childFields)),
      },
      {
        ...phoneTextField,
        layout: getFieldLayout(getPhoneNumberField(previousComplexField.childFields)),
      },
    ]
  } else {
    const [
      { layout: dropdownLayout },
      { layout: phoneTextLayout },
    ] = orderPhoneStructuresByRowsAndCols({ widgetFieldLayout: complexPhoneLayout })
    childFieldsWithCorrectLayout = [
      { ...dropdownField, layout: dropdownLayout },
      { ...phoneTextField, layout: phoneTextLayout },
    ]
  }
  return { complexPhoneLayout, childFieldsWithCorrectLayout }
}

export const getCountryCodePlaceholderChoice = (countryCodeField: FormField) => {
  if (!countryCodeField) {
    return CountryCodePlaceholderChoice.None
  }
  if (!_.isEmpty(countryCodeField.defaultValue)) {
    return CountryCodePlaceholderChoice.CodeList
  }
  if (!_.isEmpty(_.get(countryCodeField.placeholder, 'text'))) {
    return CountryCodePlaceholderChoice.PlaceholderText
  }
  return CountryCodePlaceholderChoice.None
}

export const isCountriesCodesKeys = (val): val is CountriesCodesKeys => !!COUNTRIES_CODES[val]

export const getCountryCodeDataFromUserGEO = (userGEO: CountriesCodesKeys) =>
  userGEO && isCountriesCodesKeys(userGEO)
    ? {
        data: {
          value: getCountryCodeByGEO(userGEO),
          placeholder: {
            value: '',
            text: '',
          },
        },
      }
    : {}
