import _ from 'lodash'
import { getFieldPluginsExtraData, FieldExtraData } from './fields/field-types-data'
import { Theme, FormPlugin } from '@wix/forms-common'
import { fetchRetry } from '../../../utils/fetch-utils'
import {
  PRESET_STATIC_URL,
  SPECIFIC_COMPONENTS_THEMES_STATIC_URL,
  THEMES_STATIC_URL,
} from '../../../constants/resources'
import { fieldsStore } from './fields/fields-store'

const presets: { [key: string]: Promise<Response> } = {}

export const addPreset = (presetKey: string, presetValue) => {
  presets[presetKey] = presetValue
}

export const getFormPreset = (ravenInstance) => async (presetKey: string, locale = 'en') => {
  return (await presets[presetKey]) || fetchFormPreset(ravenInstance)(presetKey, locale)
}

const fetchFormPreset = (ravenInstance) => async (presetKey, locale) => {
  const response = await fetchRetry(ravenInstance)(
    `${PRESET_STATIC_URL}/${presetKey}/${presetKey}.preset.${locale}.json`,
  )
  addPreset(presetKey, response.clone().json())
  return response.json()
}

export const getFieldPreset = ({
  fieldType,
  extraData,
  plugins,
  isResponsive,
  responsiveItemLayoutType,
  responsiveLayoutData,
}: {
  fieldType: FieldPreset
  extraData: FieldExtraData
  plugins: FormPlugin[]
  isResponsive?: boolean
  responsiveItemLayoutType?: ResponsiveItemLayoutType
  responsiveLayoutData?: ResponsiveLayoutData
  deepMerge?: boolean
}) => {
  const fieldStructure = fieldsStore.get(fieldType).fieldStructure({
    isResponsive,
    responsiveItemLayoutType,
    responsiveLayoutData,
  })

  const pluginsExtraData = getFieldPluginsExtraData({ fieldType, plugins })

  const customizer = (objValue, srcValue, key) => {
    if (key === 'options') {
      return srcValue
    }
  }
  return _.mergeWith({}, fieldStructure, extraData, pluginsExtraData, customizer)
}

export const getGlobalDesignPresets = () => {
  return {
    [Theme.PRESET01]: {
      src: `${THEMES_STATIC_URL}/preset01.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET02]: {
      src: `${THEMES_STATIC_URL}/preset02.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET03]: {
      src: `${THEMES_STATIC_URL}/preset03.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET04]: {
      src: `${THEMES_STATIC_URL}/preset04.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET05]: {
      src: `${THEMES_STATIC_URL}/preset05.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET06]: {
      src: `${THEMES_STATIC_URL}/preset06.png`,
      layout: { width: '112px', height: '83px' },
    },
    [Theme.PRESET07]: {
      src: `${THEMES_STATIC_URL}/preset07.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET08]: {
      src: `${THEMES_STATIC_URL}/preset08.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET09]: {
      src: `${THEMES_STATIC_URL}/preset09.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET10]: {
      src: `${THEMES_STATIC_URL}/preset10.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET11]: {
      src: `${THEMES_STATIC_URL}/preset11.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET12]: {
      src: `${THEMES_STATIC_URL}/preset12.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET13]: {
      src: `${THEMES_STATIC_URL}/preset13.png`,
      layout: { width: '112px', height: '83px' },
    },
    [Theme.PRESET14]: {
      src: `${THEMES_STATIC_URL}/preset14.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET15]: {
      src: `${THEMES_STATIC_URL}/preset15.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET16]: {
      src: `${THEMES_STATIC_URL}/preset16.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET17]: {
      src: `${THEMES_STATIC_URL}/preset17.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET18]: {
      src: `${THEMES_STATIC_URL}/preset18.png`,
      layout: { width: '112px', height: '83px' },
    },
    [Theme.PRESET19]: {
      src: `${THEMES_STATIC_URL}/preset19.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET20]: {
      src: `${THEMES_STATIC_URL}/preset20.png`,
      layout: { width: '111px', height: '83px' },
    },
  }
}

const getComplexFieldGlobalDesignPresets = (complexFieldName: string, layout) => {
  const baseURL = `${SPECIFIC_COMPONENTS_THEMES_STATIC_URL}/${complexFieldName}`
  return {
    [`${complexFieldName}-${Theme.PRESET01}`]: {
      src: `${baseURL}/preset01.png`,
      layout,
    },
    [`${complexFieldName}-${Theme.PRESET02}`]: {
      src: `${baseURL}/preset02.png`,
      layout,
    },
    [`${complexFieldName}-${Theme.PRESET03}`]: {
      src: `${baseURL}/preset03.png`,
      layout,
    },
    [`${complexFieldName}-${Theme.PRESET04}`]: {
      src: `${baseURL}/preset04.png`,
      layout,
    },
    [`${complexFieldName}-${Theme.PRESET05}`]: {
      src: `${baseURL}/preset05.png`,
      layout,
    },
    [`${complexFieldName}-${Theme.PRESET06}`]: {
      src: `${baseURL}/preset06.png`,
      layout,
    },
  }
}

export const getComplexAddressGlobalDesignPresets = () => {
  return getComplexFieldGlobalDesignPresets('complexAddress', {
    width: '111px',
    height: '81px',
  })
}

export const getComplexPhoneGlobalDesignPresets = () => {
  return getComplexFieldGlobalDesignPresets('complexPhone', {
    width: '240px',
    height: '30px',
  })
}
