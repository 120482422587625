import { initBiLoggerForEditorApp } from '../../utils/bi'
import { EVENTS, ORIGINS, PANEL_NAMES } from '../../constants/bi'
import { GFPP, GFPP_IDS } from '../core/manifests/manifest-commons'
import { editorAppMetaData } from './editor-app'
import { FormsFieldPreset, UpgradeAlertType } from '@wix/forms-common'
import _ from 'lodash'
import CoreApi from '../core/core-api'
import {
  WidgetGfppClickedEvent,
  ComponentGfppClickedEvent,
  ComponentDeletedEvent,
  SiteWasFirstSaved,
  GlobalDesignPresetChangedEvent,
  WidgetPastedEvent,
  ConnectedComponentPastedEvent,
  StateChangedEvent,
  WidgetDuplicatedEvent,
  ConnectedComponentDuplicatedEvent,
  PlatformEvent,
  EditorPlatformApp,
  ComponentDataChanged,
  PageDuplicated,
  componentAddedToApp,
  AppActionClicked,
  solveAddWidgetLimitation,
} from '@wix/platform-editor-sdk'
import { OwnSettingsPanelProps } from '../../panels/form-settings-panel/components/form-settings-panel'
import { handleError, updateMetaSiteId, captureBreadcrumb } from './monitoring'
import { TABS } from '../../panels/form-settings-panel/constants'
import { COMPLEX_FIELDS_ROLES, FIELDS, ROLE_FORM } from '../../constants/roles'
import { FORMS_APP_DEF_ID } from '../../constants'
import { isComplexComponent } from '../core/utils'
import { PanelName } from '../../constants/panel-names'
import { getOnStageFieldsData } from './on-event-utils'
import { FailedHandleEventError } from './errors'

const handleGfppClickedImpl = async ({
  componentRef,
  id,
  api,
  role,
}: {
  componentRef: ComponentRef
  id: string
  api: CoreApi
  role?: string
}) => {
  const onFieldSettingsOpenEvent = (evid) => ({ fieldType, crmLabel, formId }) =>
    api.log({
      evid,
      field_type: fieldType,
      field_name: crmLabel,
      origin: ORIGINS.GFPP,
      form_comp_id: formId,
    })

  switch (id) {
    case GFPP_IDS.NEW_FORM_SETTINGS:
      let extraInitialData = {}
      let formComponentRef = componentRef
      let isPaymentField = false

      if (_.includes([FIELDS.ROLE_FIELD_ITEMS_LIST, FIELDS.ROLE_FIELD_CUSTOM_AMOUNT], role)) {
        isPaymentField = true
        extraInitialData = { displayedTab: TABS.PAYMENT }
        formComponentRef = await api.findComponentByRole(componentRef, ROLE_FORM)
      }

      api.managePanels.openComponentPanel<OwnSettingsPanelProps>(
        formComponentRef,
        PanelName.NEW_FORM_SETTINGS,
        ({ preset, formId }) => {
          if (isPaymentField) {
            api.log({
              evid: EVENTS.PANELS.settingsPanel.OPEN_PANEL_FROM_PAYMENT_FIELD,
              template: preset,
              form_comp_id: formId,
              fieldType: role,
            })
          } else {
            api.log({
              evid: EVENTS.PANELS.settingsPanel.OPEN_PANEL,
              template: preset,
              form_comp_id: formId,
            })
          }
        },
        extraInitialData,
        () => api.settings.onCloseSettingsPanel(componentRef),
      )
      break
    case GFPP_IDS.ADD_FIELD:
      api.managePanels.openComponentPanel(componentRef, PanelName.ADD_FIELD, ({ preset, formId }) =>
        api.log({
          evid: EVENTS.PANELS.addFieldPanel.OPEN_PANEL,
          template: preset,
          form_comp_id: formId,
        }),
      )
      break
    case GFPP_IDS.MANAGE_SUBSCRIBERS:
      api.managePanels.openModalPanel(componentRef, PanelName.FORM_MANAGE_SUBSCRIBERS, () =>
        api.log({
          evid: EVENTS.PANELS.manageSubscribersPanel.OPEN_PANEL,
          action: 'Manage Subscribers',
        }),
      )
      break
    case GFPP_IDS.CONNECT_FIELD:
      api.managePanels.openComponentPanel(
        componentRef,
        PanelName.CONNECT_FIELD,
        onFieldSettingsOpenEvent(EVENTS.PANELS.connectFieldPanel.OPEN_PANEL),
      )
      break
    case GFPP_IDS.DYNAMIC_FIELD_SETTINGS:
      api.managePanels.openDynamicFieldSettingsPanel(
        componentRef,
        onFieldSettingsOpenEvent(EVENTS.PANELS.fieldSettingsPanel.OPEN_PANEL),
      )
      break
    case GFPP_IDS.FORM_LAYOUT:
      api.managePanels.openComponentPanel(
        componentRef,
        PanelName.FORM_LAYOUT,
        ({ columns, formId }) =>
          api.log({
            evid: EVENTS.PANELS.formLayoutPanel.OPEN_PANEL,
            action: 'open',
            layout: columns === 1 ? 'single' : columns ? `${columns} column` : '2 column',
            form_comp_id: formId,
            field_type: 'form layout panel',
          }),
      )
      break
    case GFPP_IDS.SUBMIT_SETTINGS:
      api.managePanels.openComponentPanel(
        componentRef,
        PanelName.SUBMIT_SETTINGS,
        ({ preset, formId }) =>
          api.log({
            evid: EVENTS.PANELS.submitSettingsPanel.OPEN_PANEL,
            template: preset,
            form_comp_id: formId,
          }),
      )
      break
    case GFPP_IDS.RECAPTCHA_HELP:
      api.managePanels.openHelpPanel(GFPP.HELP_ID.CAPTCHA, () =>
        api.log({
          evid: EVENTS.PANELS.recaptchaHelp.OPEN_PANEL,
          field_group: 'custom',
          field_name: FormsFieldPreset.GENERAL_RECAPTCHA,
          field_type: 'Captcha',
        }),
      )
      break
    case GFPP_IDS.MANAGE_STEPS:
      api.managePanels.openComponentPanel(componentRef, PanelName.MANAGE_STEPS, ({ formId }) =>
        api.log({
          evid: EVENTS.PANELS.manageSteps.OPEN_PANEL,
          click_type: 'manage steps button',
          form_comp_id: formId,
        }),
      )
      break
    case GFPP_IDS.CHANGE_BUTTON_LABEL:
      const componentConnection = await api.getComponentConnection(componentRef)
      api.managePanels.openComponentPanel(componentRef, PanelName.CHANGE_BUTTON_LABEL, _.noop, {
        role: _.get(componentConnection, 'role'),
      })
      break
    default:
      break
  }
}

const handlePhoneGfppClicked = async ({
  componentRef,
  id,
  api,
  role,
}: {
  componentRef: ComponentRef
  id: string
  api: CoreApi
  role?: string
}) => {
  const onFieldSettingsOpenEvent = (evid) => ({ fieldType, crmLabel, formId }) => {
    api.log({
      evid,
      field_type: role || fieldType,
      field_name: crmLabel,
      origin: ORIGINS.GFPP,
      form_comp_id: formId,
    })
  }

  const openSettingsPanel = async () => {
    const mainFieldRef = await api.fields.getMainRoleField(componentRef)
    const previousFieldData = mainFieldRef && (await api.fields.getFieldData(mainFieldRef))
    const onPanelClosed = () => {
      return api.fields.getAndUpdateCrmLabel(componentRef, previousFieldData)
    }
    api.managePanels.openComponentPanel(
      componentRef,
      PanelName.COMPLEX_PHONE_SETTINGS,
      onFieldSettingsOpenEvent(EVENTS.PANELS.complexPhoneSettingsPanel.OPEN_PANEL),
      {},
      onPanelClosed,
    )
  }

  const onOpenComplexPhoneLayoutFieldPanel = ({ formId, preset }) =>
    api.log({
      evid: EVENTS.PANELS.formLayoutPanel.OPEN_PANEL,
      action: 'open',
      form_comp_id: formId,
      field_type: 'complex phone',
      template: preset,
    })

  switch (id) {
    case GFPP_IDS.COMPLEX_PHONE_LAYOUT:
      api.managePanels.openComplexLayoutFieldPanel(
        componentRef,
        PanelName.COMPLEX_PHONE_LAYOUT,
        onOpenComplexPhoneLayoutFieldPanel,
      )
      break
    case GFPP_IDS.CONNECT_FIELD:
      api.managePanels.openComponentPanel(
        componentRef,
        PanelName.CONNECT_FIELD,
        onFieldSettingsOpenEvent(EVENTS.PANELS.connectFieldPanel.OPEN_PANEL),
      )
      break
    case GFPP_IDS.COMPLEX_PHONE_SETTINGS: {
      await openSettingsPanel()
      break
    }

    default:
      break
  }
}

const handleAddressGfppClicked = async ({
  componentRef,
  id,
  api,
}: {
  componentRef: ComponentRef
  id: string
  api: CoreApi
}) => {
  const onFieldSettingsOpenEvent = (evid) => ({ fieldType, crmLabel, formId }) =>
    api.log({
      evid,
      field_type: fieldType,
      field_name: crmLabel,
      origin: ORIGINS.GFPP,
      form_comp_id: formId,
    })

  const openSettingsPanel = async () => {
    const previousFieldsData = await api.fields.getChildFieldsData(
      componentRef,
      FIELDS.ROLE_FIELD_COMPLEX_ADDRESS_WIDGET,
    )
    const onPanelClosed = async () => {
      const currentFieldsData = await api.fields.getChildFieldsData(
        componentRef,
        FIELDS.ROLE_FIELD_COMPLEX_ADDRESS_WIDGET,
      )
      const fieldsData = getOnStageFieldsData(currentFieldsData, previousFieldsData)

      return api.fields.getAndUpdateInnerCrmLabels(fieldsData)
    }

    api.managePanels.openComponentPanel(
      componentRef,
      PanelName.COMPLEX_ADDRESS_SETTINGS,
      onFieldSettingsOpenEvent(EVENTS.PANELS.complexAddressSettingsPanel.OPEN_PANEL),
      {},
      onPanelClosed,
    )
  }

  const onOpenComplexAddressLayoutFieldPanel = ({ preset, formId }) => {
    return api.log({
      evid: EVENTS.PANELS.formLayoutPanel.OPEN_PANEL,
      action: 'open',
      form_comp_id: formId,
      field_type: 'complex address',
      template: preset,
    })
  }

  switch (id) {
    case GFPP_IDS.COMPLEX_ADDRESS_LAYOUT:
      api.managePanels.openComplexLayoutFieldPanel(
        componentRef,
        PanelName.COMPLEX_ADDRESS_LAYOUT,
        onOpenComplexAddressLayoutFieldPanel,
      )
      break
    case GFPP_IDS.COMPLEX_ADDRESS_SETTINGS:
      openSettingsPanel()
      break
    case GFPP_IDS.CONNECT_FIELD:
      api.managePanels.openComponentPanel(
        componentRef,
        PanelName.CONNECT_FIELD,
        onFieldSettingsOpenEvent(EVENTS.PANELS.connectFieldPanel.OPEN_PANEL),
      )
      break
    default:
      break
  }
}

const handleAppWidgetGfppClicked = async ({
  componentRef,
  id,
}: WidgetGfppClickedEvent['eventPayload']) => {
  const api = await editorAppMetaData.getCoreApi()
  const controllerType = await api.getControllerType(componentRef)
  switch (controllerType) {
    case 'complexPhoneController': {
      return handlePhoneGfppClicked({ componentRef, id, api })
    }
    case 'complexAddressController': {
      return handleAddressGfppClicked({ componentRef, id, api })
    }
    default: {
      const formContainer = await api.getFormContainerOfAppWidget(componentRef)
      return handleGfppClickedImpl({ componentRef: formContainer, id, api })
    }
  }
}

const handleComponentGfppClicked = async ({
  componentRef,
  id,
  role,
}: ComponentGfppClickedEvent['eventPayload']) => {
  const api = await editorAppMetaData.getCoreApi()
  const { controllerRef } = await api.getComponentConnection(componentRef)
  const controllerType = await api.getControllerType(controllerRef)

  switch (controllerType) {
    case 'complexPhoneController': {
      return handlePhoneGfppClicked({ componentRef: controllerRef, id, api, role })
    }
    case 'complexAddressController': {
      return handleAddressGfppClicked({ componentRef: controllerRef, id, api })
    }
    default: {
      return handleGfppClickedImpl({ componentRef, id, api, role })
    }
  }
}

const handleComponentDelete = async ({
  componentRef,
  connections,
}: ComponentDeletedEvent['eventPayload']) => {
  const api = await editorAppMetaData.getCoreApi()

  await api.handleDelete(componentRef, connections as ComponentConnection[])
}

const handleComponentDataChanged = async ({
  compRef,
  previousData,
}: ComponentDataChanged['eventPayload']) => {
  const api = await editorAppMetaData.getCoreApi()

  await api.handleDataChanged(compRef, previousData)
}

const handlePublish = async () => {
  const api = await editorAppMetaData.getCoreApi()
  api.interactionStarted('publish-site')
  await api.sendAllFormsData()
  api.interactionEnded('publish-site')
}

const handleFirstSave = async (eventPayload: SiteWasFirstSaved['eventPayload'] | {}) => {
  const api: CoreApi = await editorAppMetaData.getCoreApi()
  const newMetaSiteId: string = _.get(eventPayload, 'metaSiteId')

  if (newMetaSiteId) {
    updateMetaSiteId(newMetaSiteId)
    const biLogger = await initBiLoggerForEditorApp(
      newMetaSiteId,
      await api.getUserId(),
      api.originEditorType(),
    )
    api.setBiLogger(biLogger)
  }

  await api.reportBiFirstSave()

  const allFormsRefsAndConnections = await api.getAllFormsRefsAndConfigs()
  await api.createMissingFormLabels(allFormsRefsAndConnections, !!newMetaSiteId)
}

const handlePresetChanged = async ({
  componentRef,
  preset,
}: GlobalDesignPresetChangedEvent['eventPayload']) => {
  const api = await editorAppMetaData.getCoreApi()
  const [connection, formRef] = await Promise.all([
    api.getComponentConnection(componentRef),
    api.getFormContainerFromControllerOrAppWidget(componentRef),
  ])
  const { role } = connection
  if (isComplexComponent(role)) {
    const biData = {
      startBi: {
        form_comp_id: await api.getFormId(formRef),
        selected_design: preset,
        field: role,
      },
    }
    await api.style.updateComplexFieldTheme(componentRef, preset, biData)
  } else {
    const [esi, form_comp_id] = await Promise.all([
      api.getEditorSessionId(),
      api.getFormId(formRef),
    ])
    const biData = {
      startBi: {
        form_comp_id,
        panel_name: PANEL_NAMES.formStylePanel,
        control_name: 'main',
        value: preset,
        esi,
      },
    }
    await api.style.updateTheme(formRef, preset, biData)
  }
}

const handleAppWidgetPasted = async ({
  componentRef,
  originalComponentRef,
}: WidgetPastedEvent['eventPayload'] | WidgetDuplicatedEvent['eventPayload']) => {
  const api = await editorAppMetaData.getCoreApi()
  const { role } = await api.getComponentConnection(componentRef)

  if (_.includes(COMPLEX_FIELDS_ROLES, role)) {
    await api.fields.onDuplicateComplexField(componentRef)
  } else {
    await api.addForm.handleDuplicatedForm({
      controllerRef: componentRef,
      originalControllerRef: originalComponentRef,
    })
  }
}

const handleConnectedComponentPasted = async ({
  componentRef,
}:
  | ConnectedComponentPastedEvent['eventPayload']
  | ConnectedComponentDuplicatedEvent['eventPayload']) => {
  const api = await editorAppMetaData.getCoreApi()
  await api.fields.onDuplicateField(componentRef)
}

const handleStateChanged = async ({ componentRef }: StateChangedEvent['eventPayload']) => {
  const api = await editorAppMetaData.getCoreApi()
  await api.steps.onStateChanged({ componentRef })
}

const handlePageDuplicated = async ({
  duplicatedPageRef,
  originalPageRef,
}: PageDuplicated['eventPayload']) => {
  const api = await editorAppMetaData.getCoreApi()
  await api.handleDuplicatedPage({ pageRef: duplicatedPageRef, originalPageRef })
}

const handleComponentAddedToApp = async ({
  widgetRef,
  compRef,
}: componentAddedToApp['eventPayload']) => {
  const api = await editorAppMetaData.getCoreApi()
  await api.handleComponentAddedToApp(widgetRef, compRef)
}

const handleAppActionClicked = async ({ actionId }: AppActionClicked['eventPayload']) => {
  const api = await editorAppMetaData.getCoreApi()
  switch (actionId) {
    case 'manageApp.mainCtaClicked':
      api.managePanels.openAddPanel({ appDefId: FORMS_APP_DEF_ID })
      break
    case 'manageApp.manageSubmissionsClicked':
      api.managePanels.openBusinessManagerPanel('wix-forms')
      break
    case 'manageApp.viewTemplatesClicked':
      api.managePanels.openAddPanel({ appDefId: FORMS_APP_DEF_ID })
      break
    default:
      break
  }
}

const handleSolveAddWidgetLimitation = async ({
  origin,
}: solveAddWidgetLimitation['eventPayload']) => {
  // origin should be checked. atm this is only implemented in one origin: addNewPage flow
  const api = await editorAppMetaData.getCoreApi()
  const [esi, { ascendPlan }] = await Promise.all([
    api.getEditorSessionId(),
    api.premium.getCurrentAscendPlan(),
  ])
  // atm only new page flow is handled
  api.managePanels.openAddFormPremiumBillingPanel(UpgradeAlertType.FORMS_PAGE_LIMIT, {
    startBi: {
      form_comp_id: null,
      esi,
      origin: 'number_of_forms_alert_add_page_modal',
      current_ascend_plan: ascendPlan,
    },
  })
}

export const onEvent: EditorPlatformApp['onEvent'] = async ({
  eventPayload,
  eventType,
}: PlatformEvent) => {
  try {
    captureBreadcrumb({
      message: 'onEvent',
      category: 'on-event',
      level: 'info',
      data: { eventPayload, eventType },
    })

    switch (eventType) {
      case 'widgetGfppClicked':
        await handleAppWidgetGfppClicked(eventPayload)
        break
      case 'componentGfppClicked':
        await handleComponentGfppClicked(eventPayload)
        break
      case 'componentDeleted':
        await handleComponentDelete(eventPayload)
        break
      case 'componentDataChanged':
        await handleComponentDataChanged(eventPayload)
        break
      case 'siteWasPublished':
        await handlePublish()
        break
      case 'pageDuplicated':
        await handlePageDuplicated(eventPayload)
        break
      case 'siteWasFirstSaved':
        await handleFirstSave(eventPayload || {})
        break
      case 'componentAddedToApp':
        await handleComponentAddedToApp(eventPayload)
        break
      case 'globalDesignPresetChanged':
        await handlePresetChanged(eventPayload)
        break
      case 'widgetPasted':
        await handleAppWidgetPasted(eventPayload)
        break
      case 'widgetDuplicated':
        await handleAppWidgetPasted(eventPayload)
        break
      case 'connectedComponentPasted':
        await handleConnectedComponentPasted(eventPayload)
        break
      case 'connectedComponentDuplicated':
        await handleConnectedComponentPasted(eventPayload)
        break
      case 'stateChanged':
        await handleStateChanged(eventPayload)
        break
      case 'appActionClicked':
        await handleAppActionClicked(eventPayload)
        break
      case 'solveAddWidgetLimitation':
        await handleSolveAddWidgetLimitation(eventPayload)
        break
      default:
        break
    }
  } catch (error) {
    const customError = new FailedHandleEventError(eventType, eventPayload, error)
    handleError(customError, { tags: { eventType } })
  }
}
